<template>
  <div>
    <!-- Table Container Card -->
    <b-overlay
      :show="loading"
      spinner-variant="primary"
    >
      <b-card
        no-body
        class="mb-0"
      >
        <div class="m-2">
          <b-row
            v-if="title"
            class="mb-2"
          >
            <b-col>
              <h4>
                {{ title }}
              </h4>
            </b-col>
          </b-row>
          <!-- Table Top -->
          <b-row>
            <!-- Per Page -->
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <label>Показать</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
              />
              <label>элементов</label>
            </b-col>

            <!-- Search -->
            <b-col
              cols="12"
              md="6"
            >
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                  v-model.trim="searchQuery"
                  class="d-inline-block"
                  :class="{
                    'mr-1': $store.getters['workingMode/checkPermission']('create_projects')
                  }"
                  placeholder="Поиск..."
                />
                <router-link
                  v-if="$store.getters['workingMode/checkPermission']('create_projects')"
                  v-slot="{navigate}"
                  :to="{ name: 'projects.create' }"

                  custom
                >
                  <b-button
                    variant="primary"
                    @click="navigate"
                  >
                    <span class="text-nowrap">Создать</span>
                  </b-button>
                </router-link>
              </div>
            </b-col>
          </b-row>
        </div>

        <b-table
          ref="refCompanyListTable"
          class="position-relative list-view-table"
          :items="items"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="Ничего не найдено!"
          :sort-desc.sync="isSortDirDesc"
          no-local-sorting
        >
          <template #cell(name)="data">
            <b-link :to="{ name: 'projects.show', params: { id: data.item.id } }">
              {{ data.item.name }}
            </b-link>
          </template>
          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >

              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item :to="{ name: 'projects.show', params: { id: data.item.id } }">
                <feather-icon icon="FileTextIcon" />
                <span class="align-middle ml-50">Посмотреть</span>
              </b-dropdown-item>

              <b-dropdown-item
                v-if="$store.getters['workingMode/checkPermission']('edit_projects')"
                :to="{ name: 'projects.edit', params: { id: data.item.id } }"
              >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Изменить</span>
              </b-dropdown-item>

              <b-dropdown-item
                v-if="$store.getters['workingMode/checkPermission']('delete_projects')"
                @click="deleteItem(data.item)"
              >
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Удалить</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>

        <div class="mx-2 mb-2">
          <b-row>

            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">Показано с {{ from }} по {{ to }} из {{ totalItems }} елементов</span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >

              <b-pagination
                v-model="currentPage"
                :total-rows="totalItems"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BDropdown, BDropdownItem, BPagination, BOverlay, BLink,
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import { debounce } from 'lodash'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { getProjects, deleteProject } from '@/services/main-api/business/projects'

export default {
  name: 'ProjectListView',
  components: {
    BCard,
    BTable,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    BOverlay,
    BLink,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    companyId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      perPage: 10,
      perPageOptions: [10, 25, 50, 100],
      searchQuery: '',
      sortBy: 'name',
      isSortDirDesc: false,
      from: 0,
      to: 0,
      currentPage: 1,
      totalItems: 0,
      tableColumns: [
        { key: 'id', label: 'ID', sortable: true },
        { key: 'name', label: 'Имя', sortable: true },
        { key: 'actions', label: 'Действия' },
      ],
      items: [],
    }
  },
  watch: {
    perPage() {
      this.getData()
    },
    isSortDirDesc() {
      this.getData()
    },
    sortBy() {
      this.getData()
    },
    currentPage() {
      this.getData()
    },
    searchQuery() {
      this.debouncedSearch()
    },
  },
  mounted() {
    this.getData()
  },
  methods: {
    async getData() {
      this.loading = true
      await this.getItems()
      this.loading = false
    },
    async getItems() {
      let search = null
      const searchFields = []
      if (this.searchQuery) {
        searchFields.push(`name:${this.searchQuery}`)
      }
      if (this.companyId) {
        searchFields.push(`company_id:${this.companyId}`)
      }

      if (searchFields.length) {
        search = searchFields.join(';')
      }

      try {
        const res = await getProjects({
          perPage: this.perPage,
          sortedBy: this.isSortDirDesc ? 'desc' : 'asc',
          orderBy: this.sortBy,
          page: this.currentPage,
          search,
          searchJoin: 'and',
        })
        this.items = res.data.data
        this.currentPage = res.data.meta.current_page
        this.from = res.data.meta.from
        this.to = res.data.meta.to
        this.totalItems = res.data.meta.total
      } catch (e) {
        this.items = []
      }
    },
    async deleteItem(item) {
      const resButton = await this.$swal({
        title: 'Вы уверены?',
        text: `Проект ${item.name} будет удален`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Да, удалить!',
        cancelButtonText: 'Отмена',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })

      if (resButton.value) {
        const { id } = item
        try {
          await deleteProject(item.id)
          await this.getData()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Удалено!',
              icon: 'BellIcon',
              text: 'Проект был удален.',
              variant: 'success',
            },
          })

          await this.$store.dispatch('workingMode/getData')

          if (this.$store.getters['workingMode/selected_project_id'] === id) {
            await this.$router.push({ name: 'dashboard' })
          }
        } catch (e) {
          console.log(e)
        }
      }
    },
    // eslint-disable-next-line func-names
    debouncedSearch: debounce(async function () {
      this.currentPage = 1
      await this.getData()
    }, 500),
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.list-view-table{
  min-height: 145px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
