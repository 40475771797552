<template>
  <div v-if="getItemError">
    <b-alert
      variant="danger"
      show
    >
      <h4 class="alert-heading">
        Ошибка при получении данных о компании
      </h4>
      <div class="alert-body">
        Не найдено ни одной компании с этим идентификатором.
      </div>
    </b-alert>
  </div>
  <b-overlay
    v-else
    :show="loading"
    spinner-variant="primary"
  >
    <b-card>
      <b-row>
        <b-col>
          <h4>
            {{ item.name }}
          </h4>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-table-simple
            :striped="true"
            :bordered="false"
            :borderless="true"
            :outlined="false"
            :small="false"
            :hover="false"
            :dark="false"
            :fixed="false"
            :responsive="true"
            class="mt-1"
          >
            <b-tbody>
              <b-tr>
                <b-th>
                  ID
                </b-th>
                <b-td>
                  {{ item.id }}
                </b-td>
              </b-tr>
              <b-tr>
                <b-th>
                  <feather-icon
                    icon="CalendarIcon"
                    class="mr-75"
                  />
                  Дата создания
                </b-th>
                <b-td>
                  {{ item.created_at }}
                </b-td>
              </b-tr>
              <b-tr>
                <b-th>
                  <feather-icon
                    icon="CalendarIcon"
                    class="mr-75"
                  />
                  Дата изменения
                </b-th>
                <b-td>
                  {{ item.updated_at }}
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-col>
      </b-row>
      <b-row
        v-if="$store.getters['workingMode/checkPermission']('edit_company')"
        class="mt-2"
      >
        <b-col>
          <div class="d-flex flex-wrap">
            <b-button
              v-if="item.id"
              :to="{ name: 'company.edit' }"
              variant="primary"
            >
              Изменить
            </b-button>
            <b-button
              variant="outline-danger"
              class="ml-1"
              @click="deleteItem(item)"
            >
              Удалить
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <!-- <employees-list :items="item.employees" />-->
    <projects-list
      v-if="item.id"
      title="Проекты"
      :company-id="item.id"
      class="mb-2"
    />
  </b-overlay>
</template>

<script>
import {
  BCard, BRow, BCol, BOverlay, BButton, BTableSimple, BTbody, BTr, BTd, BTh,
} from 'bootstrap-vue'
// import EmployeesList from '@/modules/company/components/EmployeesList.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ProjectsList from '@/modules/project/views/ListView.vue'
import { deleteCompany, getCompany } from '@/services/main-api/business/companies'

export default {
  name: 'ShowCompanyView',
  components: {
    BCard,
    BRow,
    BCol,
    BOverlay,
    BButton, /* EmployeesList, */
    ProjectsList,
    BTableSimple,
    BTbody,
    BTr,
    BTd,
    BTh,
  },
  data() {
    return {
      item: {},
      loading: false,
      getItemError: false,
    }
  },
  mounted() {
    if (!this.$store.getters['workingMode/mode']) {
      this.$router.push({ name: 'dashboard' })
    }
    this.getData()
  },
  methods: {
    async getData() {
      this.loading = true
      await this.getItem()
      this.loading = false
    },
    async getItem() {
      try {
        const res = await getCompany(this.$store.getters['workingMode/selected_company_id'])
        this.item = res.data.data
      } catch (e) {
        this.item = {}
        this.getItemError = true
      }
    },
    async deleteItem(item) {
      const resButton = await this.$swal({
        title: 'Вы уверены?',
        text: `Компания ${item.name} будет удалена`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Да, удалить!',
        cancelButtonText: 'Отмена',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })

      if (resButton.value) {
        try {
          await deleteCompany(item.id)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Удалено!',
              icon: 'BellIcon',
              text: 'Компания была удалена.',
              variant: 'success',
            },
          })
          await this.$store.dispatch('workingMode/getData')
          await this.$router.push({ name: 'dashboard' })
        } catch (e) {
          console.log(e)
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
